import React , {useEffect} from "react"
import BlogPage from "src/components/newHomePage/motexBlog/BlogArticle"
import "../../i18n.js"
import { navigate } from '@reach/router';


const Article = ({location,id}) => {
 
useEffect(() => {
    if(!location.state?.myProp) {
        window.location.replace('/404');
    }
    return () => {
    }
}, []);
 
  return  location.state?.myProp ? <BlogPage  articleData={location.state?.myProp}/> : <></>
}

export default Article
