// extracted by mini-css-extract-plugin
export var main = "BlogArticle-module--main--3LtgV";
export var progressBar = "BlogArticle-module--progressBar--vadnL";
export var backBtnWrapper = "BlogArticle-module--backBtnWrapper--1LiNu";
export var backBtn = "BlogArticle-module--backBtn--4fQG4";
export var content = "BlogArticle-module--content--3dl7s";
export var tagAndDateRow = "BlogArticle-module--tagAndDateRow--1FREF";
export var tag = "BlogArticle-module--tag--NOOYT";
export var date = "BlogArticle-module--date--IGoW7";
export var bigTitle = "BlogArticle-module--bigTitle--2X4p6";
export var loadingBar = "BlogArticle-module--loadingBar--3-YEB";