import React, { forwardRef, MutableRefObject, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import Classnames from 'classnames'

//@ts-ignore
import * as Styles from './BlogArticle.module.scss'

import { useTranslation } from 'react-i18next'
import NewNavBar from '../NewNavBar'
import ContactUsBtn from '../ContactUsBtn'
import Calendar from '../../../images/revampImg/calendar.png'
import Footer from '../Footer'
import { useResizeDetector } from 'react-resize-detector'
//@ts-ignore
import ReadingProgress from 'react-reading-progress'

import PrevArrowRed from 'src/images/revampImg/prevArrowRed.png'
import { Link } from 'gatsby'

const BlogArticle = (props: any) => {
  const [barWidth, setBarWidth] = useState(15)
  const breakpoints = useBreakpoint()
  const articleRef = useRef(null)

  const { width, ref } = useResizeDetector()
  const { t } = useTranslation()

  const backToTop = () => {
    window?.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const ReadingProgress = ({ target }) => {
    const [readingProgress, setReadingProgress] = React.useState(0)
    const scrollListener = () => {
      if (!target.current) {
        return
      }

      const element = target.current
      const totalHeight = element.clientHeight - element.offsetTop - window.innerHeight + 300
      const windowScrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0

      if (windowScrollTop === 0) {
        return setReadingProgress(0)
      }

      if (windowScrollTop > totalHeight) {
        return setReadingProgress(100)
      }

      setReadingProgress((windowScrollTop / totalHeight) * 100)
    }

    React.useEffect(() => {
      window.addEventListener('scroll', scrollListener)
      return () => window.removeEventListener('scroll', scrollListener)
    })

    return <div className={Styles.loadingBar} style={{ width: `${readingProgress}%` }} />
  }

  return (
    <>
      <div id="mainTop"></div>
      <NewNavBar notTransparent={true} />

      <main ref={ref} className={Styles.main}>
        <div className={Styles.progressBar}>
          <div className={Styles.backBtnWrapper}>
            <Link to="/blog" className={Styles.backBtn}>
              <img src={PrevArrowRed} alt="back" />
              <div>{t('TXT_Web_blog')}</div>
            </Link>
          </div>
          <ReadingProgress target={articleRef} />
        </div>

        <div className={Styles.content} ref={articleRef}>
          <div className={Styles.tagAndDateRow}>
            <div className={Styles.tag}>{t(`TXT_Tag_${props.articleData.tag}`) ?? '-'}</div>
            <div className={Styles.date}>
              <img src={Calendar} alt="calendar"></img>
              {props.articleData?.date ?? '-'}
            </div>
          </div>

          <h1 className={Styles.bigTitle}>{props.articleData?.title}</h1>

          <div style={{overflow:'scroll'}} dangerouslySetInnerHTML={{ __html: props.articleData?.content ?? '-' }} />
        </div>

        <ContactUsBtn />
      </main>
      <Footer scrollToTop={() => backToTop()} />
    </>
  )
}

export default BlogArticle
